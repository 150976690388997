/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #316ce8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #fb4f16 !default;
$orange: #f87e17 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #48b8b8 !default;
$cyan: #17a2b8 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #6a5988 !default;
$theme-secondary: #e78c04 !default;
$theme-light: #f0f0f0 !default;
$theme-danger: $danger !default;
$headline-primary: $theme-primary !default;
$headline-secondary: $theme-secondary !default;
$theme-nav-primary: $theme-secondary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color: $theme-primary !default;
$link-color-light: #ffd970 !default;

/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
$cta-red: #bb2026 !default;
$cta-orange: #ff6633 !default;
$cta-green: #5cb865 !default;
$cta-blue: $theme-primary !default;
$cta-yellow: #edb111 !default;
$cta-dark: #333 !default;
$cta-purple: #4a236d !default;

/* ----------------------------------------------------------------------------
    Text
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-secondary {
  color: $theme-secondary !important;
}
.text-secondary {
  color: #646d75 !important;
}
/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: $theme-primary !important;
}
.bg-theme-primary-light {
  background-color: lighten($theme-primary, 7.5%) !important;
}
.bg-theme-primary-gradient {
  background: rgb(106, 89, 136);
  background: linear-gradient(137deg, rgba(155, 109, 85, 1) 0%, $theme-primary 100%);
}
.bg-theme-secondary {
  background-color: $theme-secondary !important;
}
.bg-theme-light {
  background-color: $theme-light;
}

/* ----------------------------------------------------------------------------
    Borders
----------------------------------------------------------------------------- */
.border-theme-primary {
  border-color: $theme-primary !important;
}
