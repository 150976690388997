/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #333333;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #333333;
  // background: #0d153a url('/images/bg.jpg') no-repeat right center / cover;
}
.wrap {
  padding: 30px 0;
  overflow-x: clip;
}
.container-fluid {
  &.but-fixed {
    max-width: 1356px;
  }
  &.wider {
    max-width: 1800px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: darken($theme-primary, 20%) !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
  z-index: 100;
  position: relative;
}
#header-wrap {
  // background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
#header {
  // background: rgba(255, 255, 255, 0.8);
  .logos {
    max-width: 350px;
  }
  a.logo {
    border: 0;
    width: 100%;
    // max-width: 200px;
    // min-width: 200px;
    img {
      width: auto;
      height: auto;
      max-width: 220px;
    }
  }
}
#footer-wrap {
  background-color: #000;
}
.footer {
  width: 100%;
  background-color: transparent;
  color: #ddd;
  // border-top: 1px solid #e4e4e4;
  font-size: 1rem;
  a[href^='tel:'] {
    color: #ddd;
  }
  a {
    color: $link-color-light;
  }
}

#banner-wrap {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.55);
  &.contact {
    background: $theme-primary url('../images/banners/contact-sm.jpg') no-repeat 50% 30% / cover;
  }
  &.home {
    background: $theme-primary url('../images/banners/home-sm.jpg') no-repeat 50% 30% / cover;
    min-height: 250px;
  }
  &.onsite {
    background: $theme-primary url('../images/banners/onsite-sm.jpg') no-repeat 50% 30% / cover;
  }
  &.proex-1 {
    background: $theme-primary url('../images/banners/proex-1-sm.jpg') no-repeat 50% 10% / cover;
  }
  &.proex-molding {
    background: $theme-primary url('../images/banners/proex-molding-sm.jpg') no-repeat 50% 50% / cover;
  }
  &.profitable-molding {
    background: $theme-primary url('../images/banners/profitable-molding-sm.jpg') no-repeat 50% 30% / cover;
  }
  &.promolder-1 {
    background: $theme-primary url('../images/banners/promolder-1-sm.jpg') no-repeat 50% 30% / cover;
  }
  &.promolder-2 {
    background: $theme-primary url('../images/banners/promolder-2-sm.jpg') no-repeat 50% 30% / cover;
  }
  &.promolder-3 {
    background: $theme-primary url('../images/banners/promolder-3-sm.jpg') no-repeat 50% 50% / cover;
  }
  &.prothermo {
    background: $theme-primary url('../images/banners/prothermo-sm.jpg') no-repeat 50% 50% / cover;
  }
  &.seminar {
    background: $theme-primary url('../images/banners/seminar-sm.jpg') no-repeat 50% 20% / cover;
  }
}

.connect-tab {
  position: fixed;
  width: 135px;
  height: 90px;
  bottom: 50%;
  right: -70px;
  background-color: $cta-yellow;
  color: #444;
  border-radius: 8px 8px 0 0;
  text-align: center;
  rotate: 270deg;
  padding: 7px;
  font-weight: 600;
  z-index: 101;
  &:hover {
    background-color: lighten($cta-yellow, 7.5%);
  }
}

#partners-carousel-wrap {
  min-height: 200px;
  img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  img:hover {
    filter: none;
    -webkit-filter: grayscale(0);
  }
}

.bg-connected {
  background: $theme-secondary url('../images/banners/yellow-sm.jpg') no-repeat 50% 50% / cover;
}
.bg-testimonials {
  background: #000 url('../images/banners/testimonials-sm.jpg') no-repeat 50% 50% / cover;
}
.text-initial {
  text-transform: initial;
}
.boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px rgba(95, 62, 138, 0.2);
  }
}
.border-dotted {
  border-style: dotted !important;
  &:hover {
    border-style: solid !important;
  }
}

.courses {
  h3 {
    color: #555;
  }
  ul {
    li {
      border-top: 1px dotted #ccc;
      padding: 0;
      margin: 0;
      a {
        border: 0;
        display: block;
        padding: 10px 1rem;
        &:hover {
          background: #e7e7e7;
        }
      }
    }
  }
}

.card-cover {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonial {
  position: relative;
  margin-bottom: 15px;
  &:after {
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    background: $theme-light;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-width: 0 0 1px 1px;
    position: absolute;
    bottom: -8px;
    left: 46px;
    transform: rotateZ(-46deg);
  }
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.page-link:focus {
  color: $theme-primary;
}
.pagination > li.active > a {
  background: $theme-primary;
  color: #fff;
  border-color: $theme-primary;
}

.bg-none {
  background: transparent;
}

.ao-form {
  max-width: fit-content !important;
  font-weight: 600 !important;
  font-family: var(--font-family) !important;
  select,
  label.ao-combo-label.ao-column-label2 {
    font-weight: normal !important;
    font-family: var(--font-family) !important;
  }
  select {
    background-color: #fff !important;
  }
  .ao-recaptcha-wrapper {
    margin-bottom: 20px;
  }
  .ao-block-wrapper {
    padding-bottom: 5px !important;
  }
  button.ao-form-submit {
    background: $theme-primary !important;
    color: #fff !important;
    font-family: var(--font-family) !important;
    font-weight: 600 !important;
    border-color: $theme-primary !important;
    font-size: 12pt !important;
    padding: 10px 30px !important;
    &:hover {
      background: darken($theme-primary, 7.5%) !important;
      color: #fff !important;
    }
  }
  .ao-form-error-message {
    font-size: 12px;
    color: red;
  }
  .ao-block-wrapper:has(> .ao-hidden-block) {
    padding: 0 !important;
  }
  div#column-c1729002988606 {
    padding: 15px !important;
  }
}

input.addsearch {
  background: #f2f2f2
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
    no-repeat 9px center;
  padding-left: 35px;
}

.smaller {
  font-size: 0.95em;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    max-width: 250px;
  }
  #banner-wrap {
    &.contact {
      background: $theme-primary url('../images/banners/contact.jpg') no-repeat 50% 30% / cover;
    }
    &.home {
      background: $theme-primary url('../images/banners/home.jpg') no-repeat 50% 20% / cover;
      min-height: 350px;
    }
    &.onsite {
      background: $theme-primary url('../images/banners/onsite.jpg') no-repeat 50% 30% / cover;
    }
    &.proex-1 {
      background: $theme-primary url('../images/banners/proex-1.jpg') no-repeat 50% 10% / cover;
    }
    &.proex-molding {
      background: $theme-primary url('../images/banners/proex-molding.jpg') no-repeat 50% 50% / cover;
    }
    &.profitable-molding {
      background: $theme-primary url('../images/banners/profitable-molding.jpg') no-repeat 50% 30% / cover;
    }
    &.promolder-1 {
      background: $theme-primary url('../images/banners/promolder-1.jpg') no-repeat 50% 30% / cover;
    }
    &.promolder-2 {
      background: $theme-primary url('../images/banners/promolder-2.jpg') no-repeat 50% 30% / cover;
    }
    &.promolder-3 {
      background: $theme-primary url('../images/banners/promolder-3.jpg') no-repeat 50% 50% / cover;
    }
    &.prothermo {
      background: $theme-primary url('../images/banners/prothermo.jpg') no-repeat 50% 50% / cover;
    }
    &.seminar {
      background: $theme-primary url('../images/banners/seminar.jpg') no-repeat 50% 30% / cover;
    }
  }
  .bg-connected {
    background: $theme-secondary url('../images/banners/yellow.jpg') no-repeat 50% 50% / cover;
  }
  .bg-testimonials {
    background: #000 url('../images/banners/testimonials.jpg') no-repeat 50% 50% / cover;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #banner-wrap {
    &.home {
      min-height: 550px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header a.logo {
    max-width: 280px;
    img {
      max-width: 260px;
    }
  }
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
}
